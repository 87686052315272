<template>
  <div class="detail-img row d-flex justify-content-center">
    <div class="col-lg-7 col-md-10 sm-12">
      <img id="img-comunicado" class="img-fluid" :src="loadImage(release.img_release)" :alt="release.title" />
    </div>
  </div>
</template>

<script>
import { loadAPIImage } from '@/utils/images';
import {ref, computed} from '@vue/composition-api';
import store from '@/store/index';
export default {
  setup(props,context) {
    const route = context.root.$route;
    const slug = route.params.slug;
    const release = ref({});
    const loadImage = src => {
      return loadAPIImage(src)
    }
    const getReleasesListBySlug = async () => {
      const { data, status } = await store.dispatch('listBySlug', slug)
      if (status != 200) return
      release.value = data
    }
    getReleasesListBySlug();
    return {
      loadImage,
      release,
    }
  },
}
</script>

<style lang="scss" scoped>
.detail-img {
  display: flex;
  justify-content: center;
  flex-direction: row;
  .img-fluid {
    max-width: 100%;
  }
}
</style>
